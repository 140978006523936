

.link {
    font-family: "Lora";
    color: #707070;
    font-size:1.2rem;
}

.loading {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    height:100vh;
    width:100vw;
}
.loading-img {
    height:15rem;
    width:15rem;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}