
.hof {
    display:flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 2rem 0px 2rem 0rem;
    animation:fadein 3.5s;
}
