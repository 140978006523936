.overlay-parent{
    position:absolute;
    width:100%;
    min-height:100%;
    height:auto;
}
.overlay {
    position:fixed;
    background-color:#000;
    z-index: -1;
    flex:1;
    height:100vh;
    width:100%;
    opacity: .5;
}
.background {
    position:fixed;
    z-index:-2;
    min-height:100%;
    height:auto;
    width:100%;
}

.background-img {
    min-width:100vw;
    width:auto;
    height:100vh;
    min-height:100vh;
    object-fit: cover;
}
.main-overlay {
    position:relative;
    z-index: 1;
    width:100%;
    min-height:100vh;
    height:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.navbar {
    top:0;
    right:0;
    position:fixed;
    background-color:white;
    z-index:2;
}
.nav-mobile {
    top:0;
    right:0;
    position:fixed;
    background-color:white;
    margin-top:90px;
    /* z-index:2; */
}

.main {
    flex:1;
    width:100%;
    position: relative;
    height:100%;
    /* margin-top:90px; */
}
.overlay {
    background-color: rgba(0,0,0,.51);
    width:100%;
    height:100%;
    z-index: -1;
    position: absolute;
}
.background-image {
    width:100%;
    height:100%;
    z-index: -2;
    position: absolute;
}
@media only screen and (max-width: 1000px) {
    .background {
        z-index:-2;
        min-width:100%;
        height:100%;
        width:auto;
        display:flex;
        flex-direction:center;
        align-items:center;
    }
    .background-img {
        /* width:auto; */
        height:100%;
        object-fit: cover;
    }

 }