
.make-pick {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:2rem 0rem 2rem 0rem;
}
.pick-list {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:2rem 0px 2rem 0px;
    animation:fadein 3.5s;
}
.error {
    padding:2rem 1rem 2rem 1rem;
    color:red;
    margin-top: 2rem;
    font-family:"Lora";
    font-size:5rem;
}
@media only screen and (max-width: 1000px) {
    .error {
        font-size:3rem;
    }
}