.drop-down {
    width:50vw;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: cetner;
    background-color: white;
}
.drop-down-title {
    font-family: "Lora";
    color:#707070;
    font-size:3rem;
}
.drop-down-select {
    font-family: "Lora";
    color: #707070;
    font-size:3rem;
    border:none;
}
.user-box {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    width:50vw;
    min-height: 40vh;
    height:auto;
    padding: 2rem 1rem 2rem 1rem;
    margin: 3rem 0px 0rem 0rem;
}
.user-text {
    font-family:"Lora";
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .user-box {
        width: 85vw;
        /* min-height:50vh; */
    }
    .drop-down {
        width:85vw;
    }
}